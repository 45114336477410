<template>
  <div>
    <LoadingMessage v-if="isLoading"></LoadingMessage>

    <ErrorMessage v-if="error" :error="error" class="m-3"></ErrorMessage>

    <div v-if="workoutSession">
      <div>
        <SessionTimer
          v-if="workoutIsStarted && !workoutIsFinished"
          :startTime="workoutStartTime"
          class="bg-danger text-center text-light px-3 py-2"
        ></SessionTimer>

        <header class="d-flex align-items-center bg-white p-3 border-bottom">
          <div class="flex-grow-1">
            <div class="small text-muted">{{ workoutSession.date | dateReadableLong }}</div>
            <div v-if="workoutSession.name" class="fs-4 lh-sm m-0">{{ workoutSession.name }}</div>
          </div>

          <SubmitButton
            @click="startWorkout"
            v-if="!workoutIsStarted && !workoutIsFinished"
            :text="'Start workout'"
            :isLoading="isStarting"
            :disabled="isStarting"
            class="btn btn-primary ms-3"
          ></SubmitButton>

          <SubmitButton
            @click="confirmFinishWorkout"
            v-if="workoutIsStarted && !workoutIsFinished"
            :text="'End workout'"
            :isLoading="isFinishing"
            :disabled="isFinishing"
            class="btn btn-danger ms-3"
          ></SubmitButton>
        </header>
      </div>

      <div v-if="sessionIsEmpty" class="m-3">No exercise</div>

      <div v-else class="container-fluid my-3">
        <SessionSummary
          v-if="!isFocusMode && workoutIsFinished"
          :startTime="workoutStartTime"
          :finishTime="workoutFinishTime"
          class="my-3"
        ></SessionSummary>

        <div v-show="!isFocusMode" class="card my-3">
          <div class="card-body py-1">
            <WorkoutExerciseList
              @select="selectExercise($event)"
            ></WorkoutExerciseList>
          </div>
        </div>

        <WorkoutExerciseSlider
          @finish="finishWorkout"
          @close="deselectExercise"
          v-show="isFocusMode"
          :activeExerciseId="activeExerciseId"
          class="my-3"
        ></WorkoutExerciseSlider>

        <Comment
          v-show="!isFocusMode"
          :session="workoutSession"
          class="card"
        >
          <div class="card-body fs-5 pb-0">Comments</div>
        </Comment>
      </div>

      <portal to="modal">
        <transition name="modal">
          <ConfirmModal
            @confirm="flagUnmarkedExercises"
            @close="showConfirmModal = false"
            v-model="showConfirmModal"
            v-if="showConfirmModal"
            :message="`
              You have not completed all exercises in this session.
              Do you want to finish anyway?
            `"
          ></ConfirmModal>
        </transition>
      </portal>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Workout',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
    SessionTimer: () => import('@/components/SessionTimer'),
    SessionSummary: () => import('@/components/SessionSummary'),
    WorkoutExerciseList: () => import('@/components/WorkoutExerciseList'),
    WorkoutExerciseSlider: () => import('@/components/WorkoutExerciseSlider'),
    Comment: () => import('@/components/comment/Comment'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapState('workout', ['workoutSession', 'workoutStartTime', 'workoutFinishTime']),
    ...mapGetters('workout', ['workoutIsStarted', 'workoutIsFinished', 'workoutUnmarkedExercises', 'workoutCompletionDuration']),
    sessionId() {
      return this.$route.params.sessionId;
    },
    sessionIsEmpty() {
      if (!this.workoutSession) return true;
      const exercises = this.workoutSession.exercises.filter((exercise) => exercise.type !== 'section');
      return !exercises.length;
    },
    firstExercise() {
      if (!this.workoutSession) return null;
      return this.workoutSession.exercises.find((exercise) => exercise.type !== 'section');
    },
  },
  watch: {
    isFocusMode() {
      window.scrollTo(0, 0);
    },
  },
  methods: {
    async fetchSession() {
      this.isLoading = true;
      try {
        const { sessionId, authUser } = this;
        await this.$store.dispatch('workout/fetchWorkoutSession', {
          sessionId,
          athleteId: authUser.userId,
          action: 'view',
        });
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    async startWorkout() {
      this.isStarting = true;
      try {
        const { sessionId, authUser } = this;
        await this.$store.dispatch('workout/startWorkout', {
          sessionId,
          athleteId: authUser.userId,
        });
        if (this.firstExercise) {
          this.selectExercise(this.firstExercise.exerciseId);
        }
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isStarting = false;
      }
    },
    async finishWorkout() {
      this.isFocusMode = false;
      this.isFinishing = true;
      try {
        const { sessionId, authUser } = this;
        await this.$store.dispatch('workout/finishWorkout', {
          sessionId,
          athleteId: authUser.userId,
        });
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isFinishing = false;
      }
    },
    confirmFinishWorkout() {
      if (this.workoutUnmarkedExercises.length) {
        this.showConfirmModal = true;
        return;
      }
      this.finishWorkout();
    },
    async flagUnmarkedExercises() {
      this.showConfirmModal = false;
      this.isFinishing = true;
      try {
        const { sessionId, authUser } = this;

        const promises = Object.values(this.workoutUnmarkedExercises).map((exerciseId) => {
          const { userId } = authUser;
          return this.$store.dispatch('workout/setExerciseCompletion', {
            sessionId,
            exerciseId,
            userId,
            isCompleted: false,
            note: null,
          });
        });

        await Promise.all(promises);
        await this.finishWorkout();
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isFinishing = false;
      }
    },
    selectExercise(exerciseId) {
      this.activeExerciseId = exerciseId;
      this.isFocusMode = true;
    },
    deselectExercise() {
      this.isFocusMode = false;
      this.activeExerciseId = null;
    },
  },
  data() {
    return {
      showConfirmModal: false,
      isLoading: false,
      isStarting: false,
      isFinishing: false,
      isFocusMode: false,
      error: null,
      activeExerciseId: null,
    };
  },
  mounted() {
    this.fetchSession();
  },
  destroyed() {
    this.$store.dispatch('workout/resetWorkout');
  },
};
</script>
